import { types as t, getParent, flow } from 'mobx-state-tree';
import { File, IsoDate } from '../models/BaseModel';

export const Profile = t
  .model('User', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__uid: t.maybeNull(t.number),
      // langcode: t.maybeNull(t.string),
      preferred_langcode: t.string,
      timezone: t.maybeNull(t.string),
      field_display_name: t.maybeNull(t.string),
      field_first_name: t.maybeNull(t.string),
      // default_langcode: t.boolean,
      name: t.string,
      mail: t.string,
      access: t.maybeNull(t.string),
      display_name: t.string,
      field_no_registration_numbers: t.maybeNull(t.boolean),
      field_bignr: t.maybeNull(t.string),
      field_birthdate: t.maybeNull(IsoDate),
      field_company_name: t.maybeNull(t.string),
      field_gender: t.maybeNull(t.string),
      field_initials: t.maybeNull(t.string),
      field_kabiznr: t.maybeNull(t.string),
      field_kngfnr: t.maybeNull(t.string),
      field_krmnr: t.maybeNull(t.string),
      field_last_name: t.maybeNull(t.string),
      field_rbnr: t.maybeNull(t.string),
      field_receive_email_notification: t.maybeNull(t.boolean),
      login: t.maybeNull(IsoDate),
      zoho_id: t.maybeNull(t.string),
      field_accountants_type: t.maybeNull(t.string),
      field_fysio_deelregister: t.array(t.maybeNull(t.string)),
      field_rechtsgebieden: t.array(t.maybeNull(t.string)),
      field_nirv_number: t.maybeNull(t.string),
      field_slpo_id: t.maybeNull(t.string),
      field_function: t.maybeNull(t.string),
      field_somn_member: t.maybeNull(t.boolean),
      field_vvn_nr: t.maybeNull(t.string),
      field_nova_number: t.maybeNull(t.string)
    }),
    relationships: t.model({
      user_picture: t.maybeNull(
        t.model({
          data: t.maybeNull(File)
        })
      )
    })
  })
  .actions((self) => ({
    patchPicture: flow(function* patchPicture(entity, file, field) {
      try {
        const response = yield getParent(self, 1).lmsApi.postImage(entity, file, field);
        delete response.data.relationships;
        self.relationships.user_picture.data = response.data;
        return self.profile;
      } catch (e) {
        //
        return null;
      }
    })
  }));
