export const conditionalFields = {
  Advocaat: [
    {
      name: 'field_nova_number',
      label: 'BAR nummer',
      type: 'text',
      required: true,
      helperText: 'Gelieve uw NOvA / BAR registratienummer in te vullen. Dit nummer staat op uw advocatenpas. Indien u geen NOvA / BAR registratienummer heeft, kunt u dat hieronder aangeven.',
      msg: window.i18next.t('account.conditionalFields.advocaat')
    },
    {
      name: 'field_rechtsgebieden[]',
      label: 'Rechtsgebied(en)',
      type: 'select',
      multiple: true,
      required: true,
      items: [
        { name: 'Aanbestedingsrecht', value: 'Aanbestedingsrecht' },
        { name: 'Agrarisch recht', value: 'Agrarisch recht' },
        { name: 'Algemene praktijk', value: 'Algemene praktijk' },
        { name: 'Ambtenarenrecht', value: 'Ambtenarenrecht' },
        { name: 'Arbeidsrecht', value: 'Arbeidsrecht' },
        { name: 'Asiel- en vluchtelingenrecht', value: 'Asiel- en vluchtelingenrecht' },
        { name: 'Belastingrecht', value: 'Belastingrecht' },
        { name: 'Bestuursrecht', value: 'Bestuursrecht' },
        { name: 'Burgerlijk procesrecht', value: 'Burgerlijk procesrecht' },
        { name: 'Cassatie', value: 'Cassatie' },
        { name: 'Economisch ordeningsrecht', value: 'Economisch ordeningsrecht' },
        { name: 'Erfrecht', value: 'Erfrecht' },
        { name: 'Financieel recht a. Bankrecht', value: 'Financieel recht a. Bankrecht' },
        { name: 'Gezondheidsrecht', value: 'Gezondheidsrecht' },
        { name: 'Huurrecht', value: 'Huurrecht' },
        { name: 'Informatierecht', value: 'Informatierecht' },
        { name: 'Insolventierecht', value: 'Insolventierecht' },
        { name: 'Intellectueel eigendomsrecht', value: 'Intellectueel eigendomsrecht' },
        { name: 'Letselschaderecht', value: 'Letselschaderecht' },
        { name: 'Omgevingsrecht', value: 'Omgevingsrecht' },
        { name: 'Ondernemingsrecht', value: 'Ondernemingsrecht' },
        { name: 'Onderwijsrecht', value: 'Onderwijsrecht' },
        { name: 'Onteigeningsrecht', value: 'Onteigeningsrecht' },
        { name: 'Personen- en Familierecht', value: 'Personen- en Familierecht' },
        { name: 'Privacy recht', value: 'Privacy recht' },
        { name: 'Psychiatrisch patiëntenrecht', value: 'Psychiatrisch patiëntenrecht' },
        { name: 'Slachtofferrecht', value: 'Slachtofferrecht' },
        { name: 'Sociaal-zekerheidsrecht', value: 'Sociaal-zekerheidsrecht' },
        { name: 'Sportrecht', value: 'Sportrecht' },
        { name: 'Strafrecht', value: 'Strafrecht' },
        { name: 'Transport- en handelsrecht', value: 'Transport- en handelsrecht' },
        { name: 'Tuchtrecht', value: 'Tuchtrecht' },
        { name: 'Vastgoedrecht', value: 'Vastgoedrecht' },
        { name: 'Verbintenissenrecht', value: 'Verbintenissenrecht' },
        { name: 'Verzekeringsrecht', value: 'Verzekeringsrecht' },
        { name: 'Vreemdelingenrecht', value: 'Vreemdelingenrecht' },
        { name: 'Overig', value: 'Overig' }
      ],
      helperText:
        'Om u een volledig cursusaanbod te kunnen blijven aanbieden vragen wij u naar uw geregistreerde rechtsgebieden. Indien u geen rechtsgebieden heeft geregistreerd bij de NOvA, kunt u dat hieronder aangeven.',
      msg: window.i18next.t('account.conditionalFields.advocaat')
    }
  ],
  Accountant: [
    {
      name: 'field_rbnr',
      label: 'RB nummer',
      type: 'text',
      required: true,
      helperText:
        'Bent u lid van het RB (Register Belastingadviseurs), dan graag hierboven uw RB-lidmaatschapsnummer invullen. Wij hebben dit nummer nodig om uw behaalde PE-punten automatisch door te geven aan het RB.'
    },
    {
      name: 'field_nirv_number',
      label: 'NIRV registratienummer',
      type: 'text',
      required: true
    },
    {
      name: 'field_accountants_type',
      label: 'Type accountant',
      type: 'select',
      multiple: null,
      required: true,
      items: [
        {
          name: 'Geen accountant',
          value: 'geen_accountant'
        },
        {
          name: 'Openbaar accountant',
          value: 'openbaar_accountant'
        },
        {
          name: 'Intern accountant',
          value: 'intern_accountant'
        },
        {
          name: 'Overheidsaccountant',
          value: 'overheidsaccountant'
        },
        {
          name: 'Accountant in business',
          value: 'accountants_in_business'
        },
        {
          name: 'Assistent accountant',
          value: 'assistent_accountant'
        }
      ],
      helperText: 'Om u een volledig aanbod te kunnen blijven aanbieden willen wij u vragen dit veld in te vullen.',
      msg: window.i18next.t('account.conditionalFields.accountant')
    }
  ],
  Fiscalist: [
    {
      name: 'field_rbnr',
      label: 'RB nummer',
      type: 'text',
      required: true,
      helperText:
        'Bent u lid van het RB (Register Belastingadviseurs), dan graag hierboven uw RB-lidmaatschapsnummer invullen. Wij hebben dit nummer nodig om uw behaalde PE-punten automatisch door te geven aan het RB.',
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Notaris: [
    {
      name: 'field_function',
      label: 'Functie',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Notarieel medewerker': [
    {
      name: 'field_somn_member',
      label: 'Lid van het SOMN register?',
      type: 'checkbox',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Huisarts: [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Specialist ouderengeneeskunde': [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Verpleegkundig specialist': [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Apotheker: [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Tandarts: [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Apotheekhoudend huisarts': [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Arts voor verstandelijk gehandicapten': [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Fysiotherapeut: [
    {
      name: 'field_bignr',
      label: 'Big nr',
      type: 'text',
      required: true
    },
    {
      name: 'field_fysio_deelregister[]',
      label: 'Deelregister',
      type: 'select',
      multiple: true,
      required: true,
      items: [
        {
          name: 'Algemene fysiotherapie',
          value: 'algemene_fysiotherapie'
        },
        {
          name: 'Arbeidsfysiotherapie',
          value: 'arbeidsfysiotherapie'
        },
        {
          name: 'Bekkenfysiotherapie',
          value: 'bekkenfysiotherapie'
        },
        {
          name: 'Geriatriefysiotherapie',
          value: 'geriatriefysiotherapie'
        },
        {
          name: 'Hartfysiotherapie',
          value: 'hartfysiotherapie'
        },
        {
          name: 'Kinderfysiotherapie',
          value: 'kinderfysiotherapie'
        },
        {
          name: 'Longfysiotherapie',
          value: 'longfysiotherapie'
        },
        {
          name: 'Manueeltherapie',
          value: 'manueeltherapie'
        },
        {
          name: 'Oedeemfysiotherapie',
          value: 'oedeemfysiotherapie'
        },
        {
          name: 'Oncologiefysiotherapie',
          value: 'oncologiefysiotherapie'
        },
        {
          name: 'Orofaciaal fysiotherapie',
          value: 'orofaciaal_fysiotherapie'
        },
        {
          name: 'Psychosomatische fysiotherapie',
          value: 'psychosomatische_fysiotherapie'
        },
        {
          name: 'Sportfysiotherapie',
          value: 'sportfysiotherapie'
        },
        {
          name: 'Vaatfysiotherapie',
          value: 'vaatfysiotherapie'
        }
      ],
      helperText: 'Om u een volledig aanbod te kunnen blijven aanbieden willen wij u vragen dit veld in te vullen.',
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Mondhygiënist: [
    {
      name: 'field_krmnr',
      label: 'Krm nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Apothekersassistent: [
    {
      name: 'field_kabiznr',
      label: 'Kabiz nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Farmaceutisch consulent': [
    {
      name: 'field_kabiznr',
      label: 'Kabiz nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Begeleider Gehandicaptenzorg': [
    {
      name: 'field_vvn_nr',
      label: 'V&VN nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'VIG / Verpleegkundige': [
    {
      name: 'field_vvn_nr',
      label: 'V&VN nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Helpende plus / Verzorgende': [
    {
      name: 'field_vvn_nr',
      label: 'V&VN nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Wijkverpleegkundige: [
    {
      name: 'field_vvn_nr',
      label: 'V&VN nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  'Schoolleider PO': [
    {
      name: 'field_slpo_id',
      label: 'Schoolleidersregister PO nr',
      type: 'text',
      required: true,
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ],
  Default: [
    {
      msg: window.i18next.t('account.conditionalFields.default')
    }
  ]
};

export const languages = [
  { name: 'English', value: 'en' },
  { name: 'Nederlands', value: 'nl' }
];
