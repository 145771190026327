/* eslint-disable react/no-danger */
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { rawHTML } from 'helpers/Helpers';

/*
In summary, the Dropzone component provides a user interface for a dropzone area where items can be dragged and dropped.
 It can be used in various contexts, such as implementing drag-and-drop functionality for reordering items within a list. The isDropDisabled prop allows the dropzone to be dynamically enabled or disabled. 
The component is part of a larger grid-based layout and can display a title or label above the dropzone area.
*/
const bg = {
  default: '#3597ff',
  wrong: 'darkred',
  correct: 'darkgreen'
};

const Dropzone = ({ isDropDisabled, listItems, id, label }) => (
  <Grid item>
    <Typography component="div" textAlign="center" className="dropzone_label">
      {rawHTML(label) || 'opties'}
    </Typography>
    <div className="column col-4 list-container">
      <div className="divider" data-content={id.toUpperCase()} />

      <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
        {(provided) => (
          <div
            style={{ minHeight: '100px', color: 'white' }}
            className="menu hero-list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {listItems &&
              listItems.length > 0 &&
              listItems.map(({ answer, order }, index) => (
                <ListItem key={index} name={answer} style={order} index={index} isDisabled={isDropDisabled} />
              ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  </Grid>
);

const ListItem = ({ name, style, index, isDisabled }) => {
  const dontShowBg = window.store?.getFeedback1() || false;
  return (
    <Draggable key={name} draggableId={name} index={index} isDragDisabled={isDisabled}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div
            style={{ background: dontShowBg ? bg.default : bg[style] }}
            className="tile-content"
            dangerouslySetInnerHTML={{ __html: name }}
          />
        </div>
      )}
    </Draggable>
  );
};

Dropzone.propTypes = {
  isDropDisabled: PropTypes.bool,
  listItems: PropTypes.array,
  id: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.any
};

ListItem.propTypes = {
  name: PropTypes.string,
  index: PropTypes.any,
  style: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default observer(Dropzone);
