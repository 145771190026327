import { Grid, Typography } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { move } from './DragDropUtils';
import Dropzone from './DragDropZone';

/*
In summary, the Match component is used to create a matching question interface, where users can drag items to the correct positions. 
It is designed to be flexible and customizable, with the ability to enable or disable the matching interaction. 
The use of the DragDropContext allows for smooth drag-and-drop interactions between different drop zones.
*/
const Match = (props) => {
  const { items, handleInput, handleTouched, disabled, store } = props;
  const canSeeAnswers = store?.canSeeAnswers;
  const [state, setState] = useState(items);
  const { t } = useTranslation('widget');

  const isDropDisabled = disabled;

  if (!state) return 'loading';

  function getDropLists() {
    const _items = cloneDeep(items);
    delete _items.bench;
    const dropList = Object.keys(_items);
    return dropList;
  }

  const isCorrectAnswer = (newState) => {
    handleTouched(true);
    handleInput(newState);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const newState = move(state, source, destination);
    setState(newState);
    isCorrectAnswer(newState);
  };

  const dropLists = getDropLists();

  return (
    <>
      <Typography fontWeight={700}>{t('dragItem')}</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2} className="match_question" maxHeight="70vh" overflow="auto" pb={1} pr={2}>
          <Grid item md={6}>
            {dropLists?.map((i, index) => (
              <Dropzone label={state[i].label} id={i} listItems={state[i].items} isDropDisabled={isDropDisabled} key={index} />
            ))}
          </Grid>
          {!isDropDisabled && (
            <Grid item md={6}>
              <Dropzone
                id="bench"
                listItems={
                  canSeeAnswers
                    ? state?.bench?.items.map((item) => ({ ...item, answer: `(${item.nr}) - ${item.answer}` }))
                    : state?.bench?.items
                }
                isDropDisabled={isDropDisabled}
                label={t('dragOptions')}
              />
            </Grid>
          )}
        </Grid>
      </DragDropContext>
    </>
  );
};

Match.propTypes = {
  items: PropTypes.any,
  handleInput: PropTypes.func,
  handleTouched: PropTypes.func,
  disabled: PropTypes.bool,
  store: PropTypes.any
};

export default observer(Match);
