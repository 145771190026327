/* eslint-disable react/no-danger */
import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Html from 'components/widgets/Html';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { LinearProgress } from '@mui/material';
import { rawHTML } from 'helpers/Helpers';
import useWidget from './useWidget';

const HtmlQuestion = (props) => {
  const { store, widgetCode } = props;
  const { question, widget, allowedTries, PlayWidget } = useWidget(props);
  const widgetAttr = widget.attributes;
  const touched = useRef(!!question.last_response);
  const lastResponse = question.last_response ? question.last_response : '';

  const [values] = useState(lastResponse);

  const validateAnswer = () => ({ value: values, correct: true });

  const showFeedback = () => {
    if (!question.tries) return null;
    return (
      <>
        {/* show general feedback (allways show) */}
        <div>{rawHTML(widgetAttr.feedback)}</div>
        {showCorrectFeedback()}
      </>
    );
  };

  function showCorrectFeedback() {
    if (question.tries < allowedTries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;
    return rawHTML(correctFeedback);
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        {widgetAttr?.options?.feedback && <div>{rawHTML(widgetAttr.options.feedback)}</div>}
        <Html data={widgetAttr.options.htmlData || widgetAttr.question || false} />
      </PlayWidget>
    </>
  );
};

HtmlQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(HtmlQuestion);
