/* eslint-disable react/no-danger */
import { useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import inRange from 'lodash/inRange';
import Numerical from 'components/widgets/Numerical';
import { LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { useTranslation } from 'react-i18next';
import { decideUnitPos, rawHTML } from 'helpers/Helpers';
import useWidget from './useWidget';

const NumericalQuestion = (props) => {
  const { store, widgetCode } = props;
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);
  const { t } = useTranslation('widget');
  //
  const widgetAttr = widget.attributes;
  const touched = useRef(!!question.last_response);
  // E-WISE USERS Override lastresponse to see the correct answer
  let lastResponse = question.last_response ? Number(question.last_response) : 0;
  if (store.canSeeAnswers) {
    lastResponse = widgetAttr.options.value;
  }

  const answer = useRef(lastResponse);
  const isDisabled = answerDisabled || question.last_correct || question.tries >= allowedTries || false;

  // Check if question is range question
  const isRangeQuestion = widgetAttr.options.valueEnd > widgetAttr.options.value;

  // Answer options
  const isInRange = () => inRange(answer.current, widgetAttr.options.value, widgetAttr.options.valueEnd);
  const isExact = () => Number(answer.current) === Number(widgetAttr.options.value);
  const answerIsCorrect = isRangeQuestion ? isInRange() : isExact();
  // is answer less or more than the correct answer
  const isLess = () => Number(answer.current) < Number(widgetAttr.options.value);

  const handleInput = (value) => {
    answer.current = value;
  };

  const validateAnswer = () => {
    if (answer.current === 0) return false;
    const isCorrect = isRangeQuestion ? isInRange() : isExact();
    return { value: answer.current.toString(), correct: isCorrect };
  };

  function getOptionsFeedback() {
    if (answerIsCorrect) return 'feedbackCorrect';
    return isLess() ? 'feedbackLess' : 'feedbackMore';
  }

  // show feedback after an answer
  const showFeedback = () => {
    if (!question.tries) return null;
    const isCorrect = answerIsCorrect;
    const respAnswer = isCorrect ? t('Correct') : t('Incorrect');
    // try again button text
    const tryAgain = !isCorrect && question.tries < allowedTries ? t('Try again') : '';
    //
    const correctAnswer = isRangeQuestion
      ? `${t('tussen')} ${decideUnitPos(widgetAttr.options.unit, widgetAttr.options.value)} ${t('en')} ${decideUnitPos(
          widgetAttr.options.unit,
          widgetAttr.options.valueEnd
        )}`
      : widgetAttr.options.value;

    // get feedback for given answer: feedbackCorrect, feedbackLess, feedbackMore
    const respAnswerFb = widgetAttr.options[getOptionsFeedback()];

    // show correct answer + feedback in case of 2nd answer wrong
    const explanationTitle = !isCorrect && question.tries === allowedTries ? t('feedback') : '';
    const explanationAnswer = !isCorrect && question.tries === allowedTries ? `${t('The correct answer is')} ${correctAnswer}` : '';

    return (
      <div className="feedback_block">
        {/* Title correct or incorrect */}
        <b dangerouslySetInnerHTML={{ __html: respAnswer }} className={isCorrect ? 'correct-answer' : 'wrong-answer'} />

        {/* show feedback for given answer: feedbackCorrect, feedbackLess, feedbackMore  */}
        <div className="respAnswerFeedback">{rawHTML(respAnswerFb)}</div>

        {/* show / explain correct answer in case of 2nd answer wrong */}
        {!isCorrect && question.tries === allowedTries && (
          <Explanation explanationTitle={explanationTitle} explanationAnswer={explanationAnswer} />
        )}

        {/* RETRY TEXT */}
        <div dangerouslySetInnerHTML={{ __html: tryAgain }} className="retry" />
      </div>
    );
  };

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        <Numerical
          answer={Number(answer.current)}
          value={lastResponse}
          options={widgetAttr.options}
          handleInput={handleInput}
          disabled={isDisabled}
          {...props}
        />
      </PlayWidget>
    </>
  );
};

NumericalQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(NumericalQuestion);

const Explanation = ({ explanationTitle, explanationAnswer }) => (
  <div>
    <b className="answer2ndTimeTitle">{explanationTitle}</b>
    <div className="answer2ndTime" dangerouslySetInnerHTML={{ __html: explanationAnswer }} />
  </div>
);

Explanation.propTypes = {
  explanationTitle: PropTypes.string,
  explanationAnswer: PropTypes.string
};
