/* eslint-disable jsx-a11y/media-has-caption */
/* Core */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import { RouterLink } from 'mobx-state-router';

/* MUI */
import { Typography, Avatar, CardMedia } from '@mui/material';
import Box from '@mui/material/Box';

/* Custom components */
import { rawHTML } from 'helpers/Helpers';
import CourseAccrEndDate from '../CourseAccrEndDate';
import RelatedCourses from '../RelatedCourses';

const MobileCourseIntroductionTabs = (props) => {
  const store = useStore();
  const { t } = useTranslation();
  const { authors, category, courseId, goals, description, data, podcast, reviewers, endDate } = props;
  const { product, productName, packageId } = store.params;

  const categoryCourses = store?.courses?.isSpecialAccessView ? false : store?.courses?.coursesByCategory('course')[category]?.length > 1;

  return (
    <Box className="content">
      <Box className="the_content">
        {podcast && (
          <Box className="course_podcast" id="Podcast">
            <Typography color="primaryBlue.main" fontSize="12px">
              {data?.attributes?.podcast_title}
            </Typography>
            <Box className="podcast_holder">
              <div className="hide_title" />
              {rawHTML(podcast)}
            </Box>
          </Box>
        )}
        {description && (
          <Box
            className="course_description"
            sx={{ li: { '&::before': { color: 'primary.main' } }, background: '#F4F7FE !important', padding: '16px' }}
            id="Information"
          >
            {rawHTML(description)}
          </Box>
        )}
        {store.showCourseAccrEndDate && <CourseAccrEndDate endDate={endDate} />}
        {goals && (
          <Box className="course_goals" component="div" id="Goals" sx={{ li: { '&::marker': { color: 'primary.main' } } }}>
            <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
              {t('course.tabs.goals')}
            </Typography>
            {rawHTML(goals)}
          </Box>
        )}
        {authors && authors.length > 0 && (
          <Box className="course_overview_quickview_authors" component="div" id="Authors">
            <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
              {t('course.tabs.author')}
            </Typography>
            {authors &&
              authors.length > 0 &&
              authors.map((author) => {
                const authorDetail = store.authors.getAuthorByUuid(author.uuid);
                const { id, fullName, img, bio, disclosure } = authorDetail;

                return (
                  <Box
                    key={id}
                    sx={{
                      display: {
                        xs: 'block',
                        lg: 'flex'
                      },
                      gap: 2
                    }}
                  >
                    <RouterLink
                      routeName="teacherDetail"
                      params={{ product, productName, packageId, teacherId: author.uuid }}
                      style={{ textDecoration: 'none', display: 'block', textAlign: 'center' }}
                    >
                      {img ? (
                        <CardMedia component="img" loading="lazy" image={img} sx={{ width: 160, height: 210, display: 'inline-block' }} />
                      ) : (
                        <Avatar data-testid="author-avatar" variant="rounded" sx={{ width: 160, height: 210, display: 'inline-block' }} />
                      )}
                    </RouterLink>
                    <Box
                      sx={{
                        mt: {
                          xs: 2,
                          lg: 0
                        }
                      }}
                    >
                      <RouterLink
                        routeName="teacherDetail"
                        params={{ product, productName, packageId, teacherId: author.uuid }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography variant="body2" component="h3" fontSize="20px" color="primaryBlue.main" fontWeight="500">
                          {fullName}
                        </Typography>
                      </RouterLink>
                      <Typography variant="div" component="div" fontSize="18px">
                        {bio}
                      </Typography>
                      {disclosure && (
                        <>
                          <Typography variant="h4" component="h4" fontSize="18px" color="text.secondary" fontStyle="italic">
                            Disclosure
                          </Typography>
                          <Typography variant="body2" fontStyle="italic" color="text.secondary">
                            {disclosure}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        {reviewers && reviewers.length > 0 && (
          <Box className="course_overview_quickview_authors" component="div" id="Reviewers">
            <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
              {t('course.tabs.reviewers')}
            </Typography>
            {reviewers &&
              reviewers.length > 0 &&
              reviewers.map((author) => {
                const authorDetail = store.authors.getAuthorByUuid(author.uuid);
                const { id, fullName, img, bio } = authorDetail;

                return (
                  <Box key={id} display="flex" gap="19px">
                    <Box>
                      <Avatar
                        variant="rounded"
                        alt={author.title}
                        src={img || 'https://i.pravatar.cc/300?img=2'}
                        sx={{ width: 160, height: 210 }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="body2" component="h3" fontSize="20px" color="primaryBlue.main" fontWeight="500">
                        {fullName}
                      </Typography>
                      <Typography variant="div" component="div" fontSize="18px">
                        {bio}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        {categoryCourses && (
          <Box>
            <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
              {t('introduction.related')}
            </Typography>
            <RelatedCourses category={category} courseId={courseId} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

MobileCourseIntroductionTabs.propTypes = {
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  category: PropTypes.string,
  courseId: PropTypes.number,

  reviewers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  goals: PropTypes.any,
  description: PropTypes.any,
  podcast: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  endDate: PropTypes.string
};

export default MobileCourseIntroductionTabs;
