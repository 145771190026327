export const resetLocalState = () => {
  sessionStorage.removeItem('gdpr'); // Remove gdpr popup
  sessionStorage.removeItem('ltiSession');
  sessionStorage.removeItem('selectedProductId');
  sessionStorage.removeItem('selectedProduct');
  sessionStorage.removeItem('selectedPackageId');
  sessionStorage.removeItem('prefBaseProduct');
  sessionStorage.removeItem('InfoRequiredMsg');
  sessionStorage.removeItem('courseEvaluation');
  sessionStorage.removeItem('forcePasswordReset');
  localStorage.removeItem('code_verifier');
};
