/* eslint-disable camelcase */
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Form } from 'components/form';
import { MySelect, MyTextField } from 'components/form/RHF';
import MyDatePicker from 'components/form/RHF/DatePicker/MyDatepicker';
import { useStore } from 'hooks';
import formProfileValidation from 'pages/account/forms/beans/Profile.ValidationSchema';
import { useTranslation } from 'react-i18next';
import Calendar from 'components/icons/Calendar';
import { addUserFields, getdirtyValues } from 'helpers/Helpers';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react';
import { conditionalFields } from 'pages/account/forms/beans/profileData';
import ProfileFields from 'pages/account/forms/ProfileFields';

const LtiUserForm = ({ open, handleClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const store = useStore();

  const validationSchema = formProfileValidation();

  if (!store.profile) return null;

  const userFields = addUserFields(store, conditionalFields);

  const {
    field_first_name,
    field_gender,
    mail,
    field_birthdate,
    field_last_name,
    field_bignr,
    field_rbnr,
    field_kngfnr,
    field_krmnr,
    field_kabiznr,
    field_initials,
    field_receive_email_notification,
    field_accountants_type,
    field_rechtsgebieden,
    field_fysio_deelregister,
    field_nirv_number,
    field_no_registration_numbers,
    field_slpo_id,
    field_function,
    preferred_langcode,
    field_somn_member,
    field_vvn_nr,
    field_nova_number
  } = store?.profile?.attributes || {};

  const initialValues = {
    mail,
    field_birthdate,
    field_gender: field_gender === null ? '' : field_gender,
    field_bignr: field_bignr === null ? '' : field_bignr,
    field_last_name,
    field_rbnr: field_rbnr === null ? '' : field_rbnr,
    field_kngfnr: field_kngfnr === null ? '' : field_kngfnr,
    field_krmnr: field_krmnr === null ? '' : field_krmnr,
    field_kabiznr: field_kabiznr === null ? '' : field_kabiznr,
    field_initials: field_initials === null ? '' : field_initials,
    field_first_name: field_first_name === null ? '' : field_first_name,
    field_receive_email_notification: field_receive_email_notification === null ? false : field_receive_email_notification,
    field_accountants_type: field_accountants_type === null ? '' : field_accountants_type,
    field_nirv_number: field_nirv_number === null ? '' : field_nirv_number,
    field_no_registration_numbers: field_no_registration_numbers === null ? '' : field_no_registration_numbers,
    field_somn_member: field_somn_member === null ? '' : field_somn_member,
    field_slpo_id: field_slpo_id === null ? '' : field_slpo_id,
    field_function: field_function === null ? '' : field_function,
    field_vvn_nr: field_vvn_nr === null ? '' : field_vvn_nr,
    field_nova_number: field_nova_number === null ? '' : field_nova_number,
    field_rechtsgebieden:
      field_rechtsgebieden === null || field_rechtsgebieden?.[0] === 'Geen rechtsgebieden geregistreerd' ? [] : field_rechtsgebieden,
    field_fysio_deelregister:
      field_fysio_deelregister === null
        ? [
            {
              name: 'Algemene fysiotherapie',
              value: 'algemene_fysiotherapie'
            }
          ]
        : field_fysio_deelregister,
    preferred_langcode
  };

  const gender = [
    { name: t('account.profile.genderOptions.male'), value: 'male' },
    { name: t('account.profile.genderOptions.female'), value: 'female' },
    { name: t('account.profile.genderOptions.other'), value: 'others' }
  ];

  const handleSubmit = async (values) => {
    const dirtyFields = getdirtyValues(values, initialValues);

    setIsSubmitting(true);
    try {
      await store.patchData(store.profile, {
        attributes: dirtyFields,
        id: store.profile.id,
        type: store.profile.type
      });

      store.showToast({ message: t('account.profile.updated', 'Profile updated'), variant: 'success' });
      handleClose();
    } catch (error) {
      store.showToast({ message: t('account.profile.error', 'Something went wrong'), variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const showEwiseTitle = ['ewise', 'cme'].includes(store.label);

  return (
    <Dialog
      open={open}
      scroll="body"
      sx={{
        '& .MuiOutlinedInput-root': {
          background: '#fafafa',
          borderRadius: '10px',
          color: '#2b3674',

          '&.MuiOutlinedInput-notchedOutline': {
            borderColor: '#fafafa'
          },

          '&.MuiInputBase-input': {
            padding: '8.5px 14px'
          },

          '&.MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#404040',
            cursor: 'not-allowed'
          }
        },

        '& .MuiInputLabel-root.Mui-disabled': {
          color: '#404040'
        },

        '& .input_select': {
          '.css-1nrlq1o-MuiFormControl-root': {
            marginTop: '16px',
            marginBottom: '8px'
          }
        }
      }}
    >
      <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors, dirty }) => (
          <>
            <DialogTitle>{showEwiseTitle ? t('lti.confirmForm.titleEwise') : t('lti.confirmForm.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('lti.confirmForm.content')}</DialogContentText>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    name="field_initials"
                    label={t('account.profile.initials')}
                    control={control}
                    error={!!errors?.field_last_name}
                    helperText={errors?.field_last_name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    name="field_first_name"
                    label={t('account.profile.name')}
                    control={control}
                    error={!!errors?.field_first_name}
                    helperText={errors?.field_first_name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    name="field_last_name"
                    label={t('account.profile.surname')}
                    control={control}
                    error={!!errors?.field_last_name}
                    helperText={errors?.field_last_name?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={6} mt="-6px">
                  <MyDatePicker
                    label={t('account.profile.birthday')}
                    name="field_birthdate"
                    control={control}
                    fullWidth
                    inputFormat="dd-MM-yyyy"
                    error={errors?.field_birthdate}
                    helperText={errors?.field_birthdate?.message}
                    components={{
                      OpenPickerIcon: Calendar
                    }}
                    textFieldProps={{
                      size: 'small'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="profile_select">
                  <MySelect
                    className="input_select"
                    name="field_gender"
                    label={<div style={{ marginTop: -5 }}>{t('account.profile.gender')}</div>}
                    items={gender}
                    control={control}
                    fullWidth
                    size="small"
                  />
                </Grid>
                {Object.entries(userFields).map(([profession, fields]) =>
                  fields.map((field) => {
                    const fieldDetails = conditionalFields[profession]?.find((profField) => profField.name === field);
                    const errorField = errors[field];
                    return fieldDetails ? (
                      <Grid item xs={12} sm={6} key={field}>
                        <ProfileFields
                          name={fieldDetails.name}
                          label={
                            ['select', 'autocomplete'].includes(fieldDetails.type) ? (
                              <div style={{ marginTop: -5 }}>{fieldDetails.label}</div>
                            ) : (
                              fieldDetails.label
                            )
                          }
                          type={fieldDetails.type}
                          fullWidth
                          control={control}
                          items={fieldDetails.items}
                          multiple={fieldDetails.multiple}
                          required={fieldDetails.required}
                          error={Boolean(errorField)}
                          helperText={errorField?.message}
                          size="small"
                        />
                      </Grid>
                    ) : null;
                  })
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              {dirty ? (
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  {t('account.profile.save')}
                </LoadingButton>
              ) : (
                <Button variant="contained" type="button" onClick={handleClose}>
                  {t('lti.button.confirm')}
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

LtiUserForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default observer(LtiUserForm);
